<template>
    <div id="serve">
        <div class="home_bg w100">
            <img src="../assets/imgs/top-serve1.jpg" alt="top-serve.png" class="w100" />
            <Header></Header>
            <Culture>
                <h3 slot="title1">服务支持&服务体系</h3>
                <p slot="title3">SERVICE SUPPORT</p>
            </Culture>
        </div>
        <!-- standard -->
        <div id="standard" class="standard w1200">
            <Title>
                <h5 slot="zh">服务标准</h5>
                <span slot="en">SERVICE STANDARD</span>
            </Title>
            <p>基于4C的服务体系(L-4CS):LB Service Base On 4C</p>
            <div class="standard_f">
                <div class="standard_l">
                    <img src="../assets/imgs/serve.png" alt="serve.png" />
                </div>
                <div class="standard_r">
                    <img :src='this.features' alt="Features.png" />
                </div>
            </div>
        </div>
        <!-- standard---more -->
        <div class="more w1200">
            <div class="more1 more_items">
                <img src="../assets/imgs/integration.png" alt="integration.png" />
                <h6>资源整合</h6>
                <p>整合市场及客户的优质资源，完善的工会全领域服务网络为客户工会提供全面、高效的服务体验。</p>
            </div>
            <div class="more1 more_items">
                <img src="../assets/imgs/sever1.png" alt="sever1.png" />
                <h6>一站式服务</h6>
                <p>助力客户工会打造线上线下相互促进、有机融合的新格局。直达客户工会会员及工会组织，提供工会全领域多平台的一站式服务。使工会工作省时、省力、省心。</p>
            </div>
            <div class="more1 more_items">
                <img src="../assets/imgs/serve2.png" alt="serve2.png" />
                <h6>服务保障</h6>
                <p>专业客服响应，为职工提供精细化服务，高效实施，快速落地，敏捷迭代。通过组织在线、沟通在线、协同在线，完成会员与会员，会员与组织，组织与组织的数字化，激发创造创新力。</p>
            </div>
            <div class="more2 more_items">
                <img src="../assets/imgs/serve3.png" alt="serve3.png" />
                <h6>九层安全保障</h6>
                <p>账户安全 | 存储安全 | 容灾备份</p>
                <p>日志审计 | 加密 / 权限 | SDL规范</p>
                <p>防DDOS攻击 | 防火墙 | 隐私保护</p>
            </div>
            <div class="more2 more_items">
                <img src="../assets/imgs/serve4.png" alt="serve4.png" />
                <h6>系统定制化服务</h6>
                <p>开发工会个性化页面，流程和规则</p>
                <p>定制工会专用主题或第三方服务</p>
                <p>定制工会管理专属报告</p>
            </div>
            <div class="more2 more_items">
                <img src="../assets/imgs/serve5.png" alt="serve5.png" />
                <h6>专业团队</h6>
                <p>高效实施，快速落地，敏捷迭代</p>
                <p>专业客服响应，快速解决问题</p>
            </div>
        </div>
        <!-- service -->
        <div id="service" class="service w100">
            <img src="../assets/imgs/sever6.jpg" alt="sever6.jpg" />
            <div class="content w1200">
                <Title>
                    <h5 slot="zh">服务内容</h5>
                    <span slot="en">SERVICE CONTENT</span>
                </Title>
                <div class="content_item">
                    <div class="content_item1">
                        <h5>01.工会建会咨询服务</h5>
                        <h6>- 咨询导入</h6>
                        <p>提供合规性咨询服务</p>
                        <p>系统部署，导入会员信息</p>
                        <p>系统上线</p>
                        <p>新入职员工扫码入会</p>
                    </div>
                    <div class="content_item1">
                        <h5>02.系统平台建设规划</h5>
                        <h6>- 解决核心痛点</h6>
                        <p>建设分为以下四个步骤：</p>
                        <p>&nbsp;&nbsp;&nbsp;(1)前期准备</p>
                        <p>&nbsp;&nbsp;&nbsp;(2)方案设计与系统搭建</p>
                        <p>&nbsp;&nbsp;&nbsp;(3)系统部署</p>
                        <p>&nbsp;&nbsp;&nbsp;(4)日常使用与系统运维</p>
                    </div>
                    <div class="content_item1">
                        <h5>03.个性化需求设计开发制作</h5>
                        <h6>- 解决经费问题</h6>
                        <p>解决与现有系统的数据接口开发</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- common problem -->
        <div id="problem" class="problem w1200">
            <div class="problem_l">
                <Title>
                    <h5 slot="zh">常见问题</h5>
                    <span slot="en">COMMON PROBLEM</span>
                </Title>
                <div class="problem_l_title">
                    <h6>工惠通具体可以提供什么服务？</h6>
                    <p>- 可为客户工会提供集财务、慰问、活动、咨询、人员外包、软件为一体 的线上线下综合解决方案。</p>
                </div>
                <div class="problem_l_title">
                    <h6>工惠通平台可以展示客户工会自己的特色吗？</h6>
                    <p>- 可以。工惠通满足企业个性化定制需求，为客户搭建有文化、有特色、便捷丰富的网上职工之家。</p>
                </div>
                <div class="problem_l_title">
                    <h6>工惠通是只有app吗？</h6>
                    <p>- 工惠通支持APP下载以及微信扫码两种登录方式。也可以对接客户的企业微信号，直接跳转登录。</p>
                </div>
            </div>
            <div class="problem_r">
                <a @click="gtoProblem()" class="problem_more">
                查看更多
                </a>
            </div>
        </div>
        <!-- Footer -->
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../components/Header'
import Title from '../components/Title'
import Culture from '../components/Culture'
import Footer from '../components/Footer/FootSec.vue'
export default {
  components: {
    Header,
    Culture,
    Title,
    Footer
  },
  name: 'Serve',
  data () {
    return {
      features: require('../assets/imgs/Features@2x.png')
    }
  },
  methods: {
    gtoProblem () {
      this.$router.push('/problem')
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="less">
#serve .standard {
    // height: 720px;
    margin-bottom: 2rem;
}
.standard > p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    // margin-top: 30px;
}
.standard .standard_f {
    display: flex;
    justify-content: space-between;
}
.standard .standard_l {
    width: calc(30% - 2.5rem)
    // border-right: 1px solid #cccccc;
}
.standard .standard_l img {
    width: 84%;
}
.standard_f .standard_imgs {
    width: 30%;
    display: flex;
    justify-content: space-around;
    margin: auto 0;
}
.standard_r{
    width: calc(70% - 2.5rem);
    img{
        width: 80%;
    }
}
.advantage {
    font-size: 1.5rem;
    font-weight: 400;
    color: #666666;
}
.advantage > span {
    color: #78c9ff;
}
.advantage ul {
    margin-top: 15px;
}
.advantage li {
    font-size: 18px;
    // font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 32px;
}
.advantage1 li > span {
    position: relative;
    top: -5px;
    margin-left: 10px;
}
.advantage2 li > span {
    position: relative;
    top: -5px;
    margin-left: 10px;
}
.advantage3 li > span {
    position: relative;
    top: -5px;
    margin-right: 10px;
}
.advantage4 li > span {
    position: relative;
    top: 5px;
    margin-right: 10px;
}

#serve .more {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0.2rem;
}
.more1 {
    padding-bottom: 120px;
}
.more .more_items {
    width: 33%;
    box-sizing: border-box;
    border-left: 1px solid rgba(204, 204, 204, 0.3);
    padding: 0 20px 0 40px;
    margin-top: 5px;
}
.more .more_items img {
    height: 70px;
    margin-bottom: 1rem;
}
.more .more_items h6 {
    font-size: 1.8rem;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    margin-bottom: 3px;
}
.more .more_items p {
    font-size: 1rem;
    font-weight: 400;
    color: #898989;
    line-height: 2.5rem;
}

@media screen and (max-width: 1366px) {
    #serve .service {
        overflow: hidden;
    }
    .service .content_item {
        margin-top: 5px !important;
    }
    .service .content {
        padding-top: 10px !important;
    }
    .content .content_item .content_item1{
        padding-left: 1rem
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    #serve .service {
        overflow: hidden;
    }
    .service .content_item {
        margin-top: 0px !important;
    }
    .problem{
        padding: 0px !important;
    }
    .service .content {
        padding-top: 30px !important;
    }
    .content .content_item .content_item1{
        padding-left: 2rem
    }
}
@media screen and (min-width: 1601px) and (max-width: 2560px) {
    .content .content_item .content_item1{
        padding-left: 2rem
    }
}
#serve .service {
    overflow: hidden;
    height: 30rem;
}
.service img {
    height: 30rem;
    width: 100%;
    position: absolute;
    z-index: -10;
    overflow: hidden;
}
.service .content {
    padding-top: 3rem;
    #title {
        .title_top{
            span {
                color: #fff;
            }
        }
        .title_b {
            border-bottom: 0.3125rem solid#76caff;
        }
    }
}
.service .content_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 57px;
}
.content_item .content_item1 {
    width: 30%;
    box-sizing: border-box;
    padding-left: 1rem;
    background-color: #fff;
    border-radius: 1.25rem 1.25rem;
}
.service .content_item h5 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 1rem 0 0.5rem 0;
}
.service .content_item h6 {
    position: relative;
    right: -30px;
    font-size: 1.6rem;
    color: #28acff;
    line-height: 40px;
}
.service .content_item p {
    position: relative;
    right: -30px;
    font-size: 1.2rem;
    color: #898989;
    line-height: 32px;
}

#serve .problem {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 2.5rem 0 0 0;
}
.problem .problem_l {
    width: 80%;
    height: 100%;
    margin-bottom: 10px;
}
.problem .problem_l_title {
    // height: 5rem;
    margin-top: 2rem;
    margin-left: 1.5rem;
}
.problem .problem_l_title h6 {
    font-size: 1.5rem;
    color: #333333;
    line-height: 20px;
    margin: 2.5rem 0 2rem 0;
}
.problem .problem_l_title p {
    font-size: 1.4rem;
    color: #999999;
    line-height: 18px;
}
.problem .problem_r {
    width: 20%;
    height: 100%;
    // text-align: left;
}
.problem .problem_r .problem_more{
    cursor:pointer;
    line-height: 1rem;
    border: 1px solid #BABABA;
    padding: 10px 15px;
    border-radius: 10px;
    color: #999999;
}
.problem_phone {
    width: 100%;
    padding: 0 0 30px 50px;
}
.problem_phone h6 {
    font-size: 1.5rem;
    color: #222222;
    line-height: 46px;
}
.problem_phone p {
    font-size: 1.4rem;
    color: #9b9b9b;
    line-height: 26px;
}
.problem_phone img {
    position: relative;
    top: 70px;
    right: 35px;
    z-index: -10;
}
</style>
